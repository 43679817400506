import {Serializer} from '@matchsource/api-utils';
import {DocumentModel} from '@matchsource/models/documents';
import {DocumentDetails} from '@matchsource/api-generated/subject';

export const internationalFormsSerializer: Serializer<DocumentModel, DocumentDetails> = {
  toDTO({id, name}: DocumentModel): DocumentDetails {
    return {
      id,
      title: name,
    };
  },
  fromDTO(input: DocumentDetails): DocumentModel {
    return {
      id: input.id,
      name: input.title,
      isNew: false,
    };
  },
};
